.Memory {
    
    
}

.Memory textarea{
    width: 100%;
    height: 400px;
    font-size: 20px;
    font-family: "Monaco, Monospace, Consolas";
    line-height: 3em;
    padding: 0px 15px;
}