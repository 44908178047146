.MemoryRecord {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    z-index: -1;
    border-bottom: 1px solid #EEE;
    font-family: "Monaco", "Monospace", "Consolas";
}

.MemoryRecord.placeholder {
    color: #555;
    text-align: center;
    background-color: #EFEFEF;
}

.MemoryRecord.placeholder div{
    padding-bottom: 10px;
}

.MemoryRecord[isactiveinstruction=true]>div:first-child {
    background-color: #a5d6a7;
    color: white;
    font-weight: bold;
}

.MemoryRecord[isactive=true]{
    // box-shadow: 0px 0px 0px 3px #5F5;
    background-color: #c8e6c9;
}